<script setup>
import Index from "@/components/General/Auth/Index.vue";
import { useStore } from "@/core/services/store";
import { onMounted } from "vue";

const store = useStore();

function onLogin() {
  store.dispatch("resetSalesChannelManagementAppStore");
  store.dispatch("setIsDev", true);
}

onMounted(() => {
  store.dispatch("resetIsDev");
});
</script>

<template>
  <Index
    login-route-name="salesChannelManagementAppLogin"
    login-target-route="salesChannelManagementApps"
    @login="onLogin"
  ></Index>
</template>

<style lang="scss" scoped></style>
